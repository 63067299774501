/**
 * typedef meta
 * @typedef {{
 *   title: string
 *   bottomNavbar: boolean
 *   withBack: boolean
 *   sideMenu: boolean
 *   requiresAuth: boolean
 *   floatingChat: boolean
 *   footer: boolean
 *   notification: boolean
 *   cart: boolean
 * }} TProps
 */
/**
 * fn meta
 * @param {TProps} props
 * @returns {TProps}
 */
const meta = ({
  title = '', // page title (mobile)
  bottomNavbar = true, // show bottom navbar (mobile)
  withBack = false, // show back button (mobile)
  sideMenu = true, // show side menu (desktop)
  requiresAuth = false, // requires authentication (desktop / mobile)
  floatingChat = false, // show floating chat (desktop / mobile)
  footer = false, // show footer (desktop / mobile)
  notification = true, // show notification (desktop / mobile)
  cart = true, // show cart (desktop / mobile)
}) => {
  return { title, bottomNavbar, withBack, sideMenu, requiresAuth, floatingChat, footer, notification, cart }
}

const ROUTE = {
  SUPERADMIN: {},
  ADMIN: {
    INDEX: { PATH: '/admin', NAME: 'admin' },
    NOTIF: { PATH: '/admin/notification', NAME: 'admin.notification' },
    DIET: { PATH: '/admin/diet-program', NAME: 'admin.diet-program' },
    DIET_ADD: { PATH: '/admin/diet-program/add', NAME: 'admin.diet-program.add' },
    DIET_EDIT: { PATH: '/admin/diet-program/edit/:id', NAME: 'admin.diet-program.edit' },
    DIET_DETAIL: { PATH: '/admin/diet-program/:id', NAME: 'admin.diet-program.detail' },
    CLIENT: { PATH: '/admin/clients', NAME: 'admin.clients' },
    CLIENT_DETAIL: { PATH: '/admin/clients/:clientId', NAME: 'admin.clients-detail' },
    CLIENT_DETAIL_PROGRAM_DETAIL: { PATH: '/admin/clients/:clientId/p/:programId', NAME: 'admin.clients-programs' },
    CLIENT_DETAIL_PROGRAM_DETAIL_CHANGE_NUTRI: { PATH: '/admin/clients/:clientId/p/:programId/nutris', NAME: 'admin.clients-nutris' },
    CLIENT_DETAIL_PROGRAM_DETAIL_MEALPLAN_PROFILE: { PATH: '/admin/clients/:clientId/p/:programId/meal-plan/:month/profile', NAME: 'admin.clients.meal-plan.profile' },
    CLIENT_DETAIL_PROGRAM_DETAIL_MEALPLAN_SCHEDULE: { PATH: '/admin/clients/:clientId/p/:programId/meal-plan/:month/meal-plan-schedule', NAME: 'admin.clients.meal-plan.meal-plan-schedule' },
    CLIENT_DETAIL_PROGRAM_DETAIL_MEALPLAN_RECOMMENDATION: { PATH: '/admin/clients/:clientId/p/:programId/meal-plan/:month/menu-recommendation', NAME: 'admin.clients.meal-plan.menu-recommendation' },
    CLIENT_DETAIL_PROGRAM_DETAIL_QUIZIONARY: { PATH: '/admin/clients/:clientId/p/:programId/quizionary', NAME: 'admin.clients-quizionary' },
    CLIENT_DETAIL_PROGRAM_DETAIL_NOTE: { PATH: '/admin/clients/:clientId/p/:programId/note', NAME: 'admin.clients-followup-notes' },
    CLIENT_DETAIL_PROGRAM_DETAIL_PROGRESS: { PATH: '/admin/clients/:clientId/p/:programId/progress', NAME: 'admin.clients-progress' },
    COACHING: { PATH: '/admin/coaching-challenge', NAME: 'admin.coaching-challenge' },
    COACHING_ADD: { PATH: '/admin/coaching-challenge/add', NAME: 'admin.coaching-challenge.add' },
    COACHING_EDIT: { PATH: '/admin/coaching-challenge/edit/:id', NAME: 'admin.coaching-challenge.edit' },
    COACHING_CATEGORY: { PATH: '/admin/coaching-challenge/category', NAME: 'admin.coaching-challenge.category' },
    COACHING_DETAIL: { PATH: '/admin/coaching-challenge/:id', NAME: 'admin.coaching-challenge.detail' },
    MANAGE_NUTRITIONIST: { PATH: '/admin/nutritionists', NAME: 'admin.nutritionists' },
    MANAGE_NUTRITIONIST_ADD: { PATH: '/admin/nutritionists/add', NAME: 'admin.nutritionists.add' },
    MANAGE_NUTRITIONIST_EDIT: { PATH: '/admin/nutritionists/edit/:id', NAME: 'admin.nutritionists.edit' },
    MANAGE_NUTRITIONIST_DETAIL: { PATH: '/admin/nutritionists/:id', NAME: 'admin.nutritionists.detail' },
  },
  CLIENT: {},
  NUTRITIONIST: {},
  BACKEND: {
    INFO_CONSULTATION_END: { PATH: '/info-konsultasi-berakhir', NAME: 'backend.info-consultation-end' },
  },
  GENERAL: {
    TERM_CONDITION: { PATH: '/syarat-ketentuan', NAME: 'client.syaratKetentuan' },
    PRIVACY_POLICY: { PATH: '/privacy-policy', NAME: 'client.privacyPolicy' },
    SPECIAL_TERM: { PATH: '/ketentuan-khusus-layanan', NAME: 'client.ketentuanKhususLayanan' },
    BLOG: { PATH: '/blog', NAME: 'client.blog' },
    ABOUT: { PATH: '/tentang-dietela', NAME: 'client.about.dietela' },
    MAINTENANCE: { PATH: '/under-maintenance', NAME: 'under-maintenance' },
    NOT_FOUND: { PATH: '*', NAME: '404' },
  },
}

const routes = [
  // CLIENT
  {
    path: '/',
    component: () => import('@/views/client/layout.vue'),
    children: [
      {
        path: '',
        name: 'client.index',
        component: () => import('@/views/client/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          bottomNavbar: true,
          floatingChat: true,
          requiresAuth: false,
        },
      },
      {
        path: 'monitoring',
        name: 'client.monitoring',
        component: () => import('@/views/client/monitoring/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          ...meta({
            title: 'Pemantauan',
            bottomNavbar: true,
            requiresAuth: true,
          }),
        },
      },
      {
        path: 'layanan-enterprise',
        name: 'client.enterpriseService',
        component: () => import('@/views/layanan-enterprise/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Program',
          bottomNavbar: false,
          footer: true,
          withBack: true,
          notification: true,
          cart: true,
          requiresAuth: false,
          sideMenu: false,
          floatingChat: true,
        },
      },
      {
        path: 'profile/',
        name: 'client.profile',
        component: () => import('@/views/profile/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Akun',
          bottomNavbar: true,
          footer: false,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/account',
        name: 'client.profile.account',
        component: () => import('@/views/profile/account.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Akun',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/referral',
        name: 'client.profile.referral',
        component: () => import('@/views/profile/referral/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Kode AjakSehat',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          sideMenu: false,
          fullWidth: true,
          requiresAuth: true,
        },
      },

      {
        path: 'profile/referral/detail',
        name: 'client.profile.referral.detail',
        component: () => import('@/views/profile/referral/detail.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Daftar Pengguna Referral',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          sideMenu: false,
          fullWidth: true,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/referral/redeem',
        name: 'client.profile.referral.redeem',
        component: () => import('@/views/profile/referral/redeem.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Redeem',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          sideMenu: false,
          fullWidth: true,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/referral/gift/:id',
        name: 'client.profile.referral.gift.detail',
        component: () => import('@/views/profile/referral/gift/detail.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Detail Hadiah',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          sideMenu: false,
          fullWidth: true,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/referral/gift/:id/confirm',
        name: 'client.profile.referral.gift.confirm',
        component: () => import('@/views/profile/referral/gift/detail.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Konfirmasi Penukaran',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          sideMenu: false,
          fullWidth: true,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/referral/redeem-history/:id',
        name: 'client.profile.referral.redeem-history',
        component: () => import('@/views/profile/referral/redeem-history/detail.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Penukaran',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          sideMenu: false,
          fullWidth: true,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/program-history',
        name: 'client.profile.programHistory',
        component: () => import('@/views/profile/program-history.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Riwayat Program',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/program-history/:programId',
        name: 'client.profile.programDetail',
        component: () => import('@/views/profile/program-detail.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Detail Riwayat Program',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/program-history/:programId/questionnaire',
        name: 'client.profile.detail-quizionary',
        component: () => import('@/views/profile/detail/quizionary.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Detail Formulir Gizi',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: '/select-program',
        name: 'client.select-program',
        component: () => import('@/views/select-program/index.vue'),
        meta: {
          sitemap: {
            lastmod: '2023-08-18',
            priority: 1,
            changefreq: 'monthly',
          },
          title: 'Program',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: true,
          cart: true,
          floatingChat: true,
          requiresAuth: false,
        },
      },
      {
        path: '/program',
        name: 'client.program',
        component: () => import('@/views/select-program/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Program',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          requiresAuth: false,
          cart: false,
          floatingChat: true,
        },
      },
      {
        path: 'program/:id',
        name: 'client.program.detail',
        component: () => import('@/views/client/program/detail.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Program',
          bottomNavbar: false,
          footer: true,
          withBack: true,
          notification: true,
          cart: true,
          sideMenu: false,
          floatingChat: true,
          requiresAuth: false,
        },
      },
      {
        path: 'konsultasi',
        name: 'client.program.consultation',
        component: () => import('@/views/client/program/consultation.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Program Konsultasi',
          bottomNavbar: false,
          footer: true,
          withBack: true,
          notification: true,
          cart: true,
          sideMenu: false,
          floatingChat: true,
          requiresAuth: false,
        },
      },
      {
        path: 'extend-program',
        name: 'client.program.extend',
        component: () => import('@/views/client/program/extend.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Program Lanjutan',
          bottomNavbar: false,
          withBack: true,
          sideMenu: false,
          requiresAuth: true,
          floatingChat: true,
        },
      },
      {
        path: 'test',
        name: 'client.program.test',
        component: () => import('@/views/client/program/test.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Program Lanjutan',
          bottomNavbar: false,
          withBack: true,
          sideMenu: false,
          requiresAuth: true,
          floatingChat: true,
        },
      },
      {
        path: '/blank',
        name: 'client.blank',
        component: () => import('@/views/client/blank.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Not Found',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
        },
      },
      {
        path: 'profile/language',
        name: 'client.profile.language',
        component: () => import('@/views/profile/language.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Bahasa',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/help-center',
        name: 'client.profile.helpcenter',
        component: () => import('@/views/profile/help-center.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Pusat Bantuan',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: '/notification',
        name: 'client.notification',
        component: () => import('@/views/client/notification.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Notifikasi',
          bottomNavbar: true,
          footer: true,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/history-transaction',
        name: 'client.profile.historyTransaction',
        component: () => import('@/views/profile/history-transaction/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Riwayat Transaksi',
          bottomNavbar: true,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/history-transaction/:transactionId',
        name: 'client.profile.detailHistoryTransaction',
        component: () => import('@/views/profile/history-transaction/[id]/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Detail Riwayat Transaksi',
          bottomNavbar: true,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/settings',
        name: 'client.profile.settings',
        component: () => import('@/views/profile/settings.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Ubah Password',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/detail',
        name: 'client.profile.detail',
        component: () => import('@/views/profile/detail/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Lihat Akun',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: 'profile/edit',
        name: 'profile.edit',
        component: () => import('@/views/profile/edit/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Edit Akun',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: '/cart',
        name: 'client.cart',
        component: () => import('@/views/client/cart'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Keranjang',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          back: {
            name: 'client.index',
          },
          notification: true,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: '/cart/check-order',
        name: 'client.cart.check-order',
        component: () => import('@/views/client/cart/check-order'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Periksa Pesanan',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: true,
          cart: false,
          sideMenu: false,
          requiresAuth: true,
        },
      },
      {
        path: 'checkout/:id/payment',
        name: 'client.checkout.payment',
        component: () => import('@/views/client/checkout/select-payment'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Pembayaran',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          sideMenu: false,
          requiresAuth: true,
        },
      },
      {
        path: 'checkout/:id/detail',
        name: 'client.checkout.detail',
        component: () => import('@/views/client/checkout/detail'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Pembayaran',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          sideMenu: false,
          requiresAuth: true,
        },
      },
      {
        path: 'checkout/re-order',
        name: 'client.checkout.reorder',
        component: () => import('@/views/client/checkout/reorder'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: false,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: true,
        },
      },
      {
        path: 'checkout/done',
        name: 'client.checkout.done',
        component: () => import('@/views/client/checkout/done'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Pembayaran',
          bottomNavbar: false,
          footer: false,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: true,
          sideMenu: false,
        },
      },
      {
        path: '/nutritionists',
        name: 'client.nutritionists',
        component: () => import('@/views/client/nutritionists/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Ahli Gizi',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
          sideMenu: false,
        },
      },
      {
        path: 'nutritionists/done',
        name: 'client.nutritionists.done',
        component: () => import('@/views/client/checkout/done'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Pernyataan',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
          sideMenu: false,
        },
      },
      {
        path: 'nutritionists/done-extend',
        name: 'client.nutritionists.done-extend',
        component: () => import('@/views/client/checkout/done'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Perpanjang Program',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
          sideMenu: false,
        },
      },
      {
        path: 'ahli-gizi',
        name: 'client.about.ahli-gizi',
        component: () => import('@/views/ahli-gizi.vue'),
        meta: {
          sitemap: {
            lastmod: '2023-08-18',
            priority: 1,
            changefreq: 'monthly',
          },
          title: 'Ahli Gizi',
          bottomNavbar: false,
          footer: true,
          withBack: true,
          notification: true,
          cart: true,
          requiresAuth: false,
          sideMenu: false,
          floatingChat: true,
        },
      },
      {
        path: '/quizionary/:programId',
        component: () => import('@/views/client/kuisioner/container.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            redirect: 'statement',
            name: 'client.kuisioner',
          },
          {
            path: 'statement',
            name: 'client.statement',
            component: () => import('@/views/client/statement.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Pernyataan',
              bottomNavbar: false,
              footer: false,
              withBack: true,
              notification: false,
              cart: false,
              requiresAuth: true,
              sideMenu: false,
            },
          },
          {
            path: '1',
            name: 'client.kuisioner1',
            component: () => import('@/views/client/kuisioner/kuisioner-1.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Pernyataan',
              bottomNavbar: false,
              footer: false,
              withBack: true,
              notification: false,
              cart: false,
              requiresAuth: true,
              sideMenu: false,
            },
          },
          {
            path: '2',
            name: 'client.kuisioner2',
            component: () => import('@/views/client/kuisioner/kuisioner-2.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Pernyataan',
              bottomNavbar: false,
              footer: false,
              withBack: true,
              notification: false,
              cart: false,
              requiresAuth: true,
              sideMenu: false,
            },
          },
          {
            path: '3',
            name: 'client.kuisioner3',
            component: () => import('@/views/client/kuisioner/kuisioner-3-alt.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Pernyataan',
              bottomNavbar: false,
              footer: false,
              withBack: true,
              notification: false,
              cart: false,
              requiresAuth: true,
              sideMenu: false,
            },
          },
          {
            path: '4',
            name: 'client.kuisioner4',
            component: () => import('@/views/client/kuisioner/kuisioner-4-alt.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Pernyataan',
              bottomNavbar: false,
              footer: false,
              withBack: true,
              notification: false,
              cart: false,
              requiresAuth: true,
              sideMenu: false,
            },
          },
          {
            path: '5',
            name: 'client.kuisioner5',
            component: () => import('@/views/client/kuisioner/kuisioner-5.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Pernyataan',
              bottomNavbar: false,
              footer: false,
              withBack: true,
              notification: false,
              cart: false,
              requiresAuth: true,
              sideMenu: false,
            },
          },
          {
            path: '6',
            name: 'client.kuisioner6',
            component: () => import('@/views/client/kuisioner/kuisioner-6-alt.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Pernyataan',
              bottomNavbar: false,
              footer: false,
              withBack: true,
              notification: false,
              cart: false,
              requiresAuth: true,
              sideMenu: false,
            },
          },
          // {
          //   path: '7',
          //   name: 'client.kuisioner7',
          //   component: () => import('@/views/client/kuisioner/kuisioner-7.vue'),
          //   meta: {sitemap: {
          //     ignoreRoute: true,
          //   },
          //     title: 'Pernyataan',
          //     bottomNavbar: false,
          //     footer: false,
          //     withBack: true,
          //     notification: false,
          //     cart: false,
          //     requiresAuth: true,
          //     sideMenu: false,
          //   },
          // },
          {
            path: 'done',
            name: 'client.quizionary.done',
            component: () => import('@/views/client/checkout/done'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Pernyataan',
              bottomNavbar: false,
              footer: false,
              withBack: true,
              notification: false,
              cart: false,
              requiresAuth: true,
              sideMenu: false,
            },
          },
        ],
      },
      {
        path: 'questionnaire/done',
        name: 'client.questionnaire.done',
        component: () => import('@/views/client/kuisioner/notification'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Notifikasi',
          bottomNavbar: false,
          footer: false,
          withBack: true,
          notification: false,
          cart: false,
          requiresAuth: true,
          sideMenu: false,
        },
      },
      {
        path: 'pricing',
        name: 'client.pricing',
        component: () => import('@/views/pricing/index.vue'),
        meta: {
          sitemap: {
            lastmod: '2023-08-18',
            priority: 1,
            changefreq: 'monthly',
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: false,
          sideMenu: false,
          floatingChat: true,
        },
      },

      // << BUY PAGE - Start >>
      {
        path: 'buy/1',
        name: 'client.buy.step-1',
        component: () => import('@/views/buy/1'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: false,
          sideMenu: false,
        },
      },
      {
        path: 'buy/2',
        name: 'client.buy.step-2',
        component: () => import('@/views/buy/2.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: false,
          sideMenu: false,
        },
      },
      {
        path: 'buy/3',
        name: 'client.buy.step-3',
        component: () => import('@/views/buy/3.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: false,
          sideMenu: false,
        },
      },
      {
        path: 'buy/payment',
        name: 'client.buy.payment',
        component: () => import('@/views/buy/payment.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: true,
          sideMenu: false,
        },
      },
      {
        path: 'buy/4',
        name: 'client.buy.step-4',
        component: () => import('@/views/buy/4.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: false,
          sideMenu: false,
        },
      },
      // << BUY PAGE - End >>

      // << BUY PROGRAM PAGE - Start >>
      {
        path: 'buy-program/1',
        name: 'client.buy-program.step-1',
        component: () => import('@/views/buy-program/1.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: false,
          sideMenu: false,
        },
      },
      {
        path: 'buy-program/2',
        name: 'client.buy-program.step-2',
        component: () => import('@/views/buy-program/2.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: false,
          sideMenu: false,
        },
      },
      {
        path: 'buy-program/3',
        name: 'client.buy-program.step-3',
        component: () => import('@/views/buy-program/3.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: false,
          sideMenu: false,
        },
      },
      {
        path: 'buy-program/payment',
        name: 'client.buy-program.payment',
        component: () => import('@/views/buy-program/payment.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: true,
          sideMenu: false,
        },
      },
      // << BUY PROGRAM PAGE - End >>

      {
        path: 'promo/:id',
        name: 'client.promo',
        component: () => import('@/views/promo/[id].vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: '',
          bottomNavbar: false,
          footer: true,
          withBack: false,
          notification: false,
          cart: false,
          requiresAuth: false,
          sideMenu: false,
        },
      },
      {
        path: '/content-challenge',
        name: 'client.content-challenge',
        component: () => import('@/views/content-challenge/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          ...meta({
            title: 'Panduan Diet',
            bottomNavbar: false,
            withBack: true,
            requiresAuth: true,
          }),
        },
      },
      {
        path: '/content-challenge/detail/:id',
        name: 'content-challenge.detail',
        component: () => import('@/views/content-challenge/detail/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          requiresAuth: true,
          sideMenu: false,
          bottomNavbar: false,
          footer: true,
          withBack: true,
          notification: true,
          cart: false,
        },
      },
      {
        path: 'glossaries',
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: '',
            name: 'client.glossaries',
            component: () => import('@/views/glossaries/index.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Nilai Gizi',
              bottomNavbar: false,
              withBack: true,
              sideMenu: true,
              requiresAuth: true,
              floatingChat: false,
              footer: false,
              notification: false,
              cart: false,
            },
          },
          {
            path: ':glossaryId',
            component: {
              render: (h) => h('router-view'),
            },
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
            children: [
              {
                path: '',
                name: 'client.glossaries-detail',
                component: () => import('@/views/admin/glossary/detail.vue'),
                meta: {
                  sitemap: {
                    ignoreRoute: true,
                  },
                  title: 'Detail Nilai Gizi',
                  bottomNavbar: false,
                  withBack: true,
                  sideMenu: true,
                  requiresAuth: true,
                  floatingChat: false,
                  footer: false,
                  notification: false,
                  cart: false,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'meal-plan',
        component: () => import('@/views/client/meal-plan/layout.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
        children: [
          {
            path: '',
            name: 'client.meal-plan',
            component: () => import('@/views/client/meal-plan/index.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              ...meta({
                title: 'Panduan Makan',
                requiresAuth: true,
              }),
            },
          },
          {
            path: ':month/meal-plan-schedule',
            name: 'client.meal-plan.meal-plan-schedule',
            component: () => import('@/views/client/meal-plan/meal-plan-schedule/index.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Rencana Makan',
              bottomNavbar: true,
              withBack: true,
              sideMenu: true,
              requiresAuth: true,
              floatingChat: false,
              footer: false,
              notification: false,
              cart: false,
            },
          },
          {
            path: ':month/meal-plan-schedule/detail',
            name: 'client.meal-plan.meal-plan-schedule.detail',
            component: () => import('@/views/client/meal-plan/meal-plan-schedule/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Rencana Makan',
              bottomNavbar: true,
              withBack: true,
              sideMenu: true,
              requiresAuth: true,
              floatingChat: false,
              footer: false,
              notification: false,
              cart: false,
            },
          },
          {
            path: ':month/menu-recommendation',
            name: 'client.meal-plan.menu-recommendation',
            component: () => import('@/views/client/meal-plan/menu-recommendation/index.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Rekomendasi Menu',
              bottomNavbar: true,
              withBack: true,
              sideMenu: true,
              requiresAuth: true,
              floatingChat: false,
              footer: false,
              notification: false,
              cart: false,
            },
          },
          {
            path: ':month/profile',
            name: 'client.meal-plan.profile',
            component: () => import('@/views/client/meal-plan/profile/index.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Profil Gizi',
              bottomNavbar: true,
              withBack: true,
              sideMenu: true,
              requiresAuth: true,
              floatingChat: false,
              footer: false,
              notification: false,
              cart: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/checkout/redirect-midtrans-3ds',
    name: 'client.checkout.redirect-midtrans-3ds',
    component: () => import('@/views/client/checkout/redirect-midtrans-3ds.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
      requiresAuth: true,
    },
  },
  {
    path: '/auth/detail-profile',
    name: 'auth.detail-profile',
    component: () => import('@/views/auth/detail-profile.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/auth/auth.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/auth/verify',
    component: () => import('@/views/auth/verify.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/auth/resend',
    name: 'auth.resend-verify',
    component: () => import('@/views/auth/resend-verify.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'auth.forgot-password',
    component: () => import('@/views/auth/forgot-password.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/set-password',
    name: 'auth.set-password',
    component: () => import('@/views/auth/set-password.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },

  {
    path: '/post/:id',
    name: 'client.detailbanner',
    component: () => import('@/views/client/detailBanner.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
      requiresAuth: false,
    },
  },
  {
    path: '',
    component: () => import('@/views/nutritionists/layout.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
    children: [
      {
        path: '/management-client',
        name: 'nutrisionist.managementClient',
        component: () => import('@/views/nutritionists/client/management-client/index.vue'),
        meta: {
          requiresAuth: true,
          isNutritionist: true,
        },
      },
    ],
  },

  {
    path: '/history-questionnaire',
    name: 'nutrisionist.historyQuestionnaire',
    component: () => import('@/views/nutritionists/client/historyQuestionnaire.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
      requiresAuth: true,
    },
  },

  {
    path: '/profile',
    component: () => import('@/views/profile/layout.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
      requiresAuth: true,
    },
    children: [
      // {
      //   path: '',
      //   name: 'client.profile',
      //   component: () => import('@/views/profile/index.vue'),
      //   beforeEnter: (to, from, next) => {
      //     let match = window.matchMedia('(min-width: 30em)')

      //     if (match.matches) {
      //       return next({ name: 'client.profile.detail' })
      //     }
      //     return next()
      //   },
      // },
      // {
      //   path: 'detail',
      //   name: 'client.profile.detail',
      //   component: () => import('@/views/profile/detail/index.vue'),
      // },
      {
        path: 'data-diet',
        name: 'client.profile.data-diet',
        component: () => import('@/views/profile/data-diet.vue'),
      },
      {
        path: 'program',
        name: 'client.profile.program',
        component: () => import('@/views/profile/program.vue'),
      },
      {
        path: 'pembelian',
        name: 'client.profile.pembelian',
        component: () => import('@/views/profile/pembelian.vue'),
      },
      // {
      //   path: 'settings',
      //   name: 'client.profile.settings',
      //   component: () => import('@/views/profile/settings.vue'),
      // },
      // {
      //   path: 'questionary/:programId',
      //   name: 'client.profile.detail-quizionary',
      //   component: () => import('@/views/profile/detail/quizionary.vue'),
      // },
    ],
  },
  {
    path: '/nutritionist',
    component: () => import('@/views/nutritionists/layout.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
      isNutritionist: true,
      requiresAuth: true,
    },
    children: [
      {
        path: '/nutritionist/notification',
        name: 'nutritionist.notification',
        component: () => import('@/views/nutritionists/notification.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Nilai Gizi',
          bottomNavbar: false,
          withBack: true,
          sideMenu: true,
          requiresAuth: true,
          floatingChat: false,
          footer: false,
          notification: false,
          cart: false,
        },
      },
      {
        path: '/nutritionist/glossaries',
        name: 'nutritionist.glossaries',
        component: () => import('@/views/nutritionists/glossaries/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Nilai Gizi',
          bottomNavbar: false,
          withBack: true,
          sideMenu: true,
          requiresAuth: true,
          floatingChat: false,
          footer: false,
          notification: false,
          cart: false,
        },
      },
      {
        path: '/nutritionist/glossaries/:glossaryId',
        component: {
          render: (h) => h('router-view'),
        },
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
        children: [
          {
            path: '/nutritionist/glossaries/:glossaryId',
            name: 'nutritionist.glossaries-detail',
            component: () => import('@/views/admin/glossary/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              title: 'Detail Nilai Gizi',
              bottomNavbar: false,
              withBack: true,
              sideMenu: true,
              requiresAuth: true,
              floatingChat: false,
              footer: false,
              notification: false,
              cart: false,
            },
          },
        ],
      },
      {
        path: ':clientId',
        component: {
          render: (h) => h('router-view'),
        },
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
        children: [
          {
            path: '',
            name: 'nutri.detail-client',
            component: () => import('@/views/nutritionists/client/detail.vue'),
            meta: {
              isNutritionist: true,
            },
          },
          {
            path: 'meal-plan',
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              mealPlanNav: true,
            },
            component: {
              name: 'MealPlanContainer',
              render(h) {
                return h('router-view')
              },
            },
            children: [
              {
                path: '',
                name: 'nutri.meal-plan',
                component: () => import('@/views/meal-plan/index.vue'),
                meta: {
                  isNutritionist: true,
                },
              },
              {
                path: 'edit',
                name: 'nutri.meal-plan.edit',
                component: () => import('@/views/meal-plan/edit.vue'),
                meta: {
                  isNutritionist: true,
                },
              },
            ],
          },
          {
            path: 'recommended-menu',
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              mealPlanNav: true,
            },
            component: {
              name: 'RecommendedMenuContainer',
              render(h) {
                return h('router-view')
              },
            },
            children: [
              {
                path: '',
                name: 'nutri.rekomendasi-menu',
                component: () =>
                  import('@/views/meal-plan/menu-recommendation/index.vue'),
                meta: {
                  isNutritionist: true,
                },
              },
              {
                path: 'edit',
                name: 'nutri.rekomendasi-menu.edit',
                component: () =>
                  import('@/views/meal-plan/menu-recommendation/edit.vue'),
                meta: {
                  isNutritionist: true,
                },
              },
            ],
          },
          {
            path: 'health-profile',
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              mealPlanNav: true,
            },
            component: {
              name: 'HealthProfileContainer',
              render(h) {
                return h('router-view')
              },
            },
            children: [
              {
                path: '',
                name: 'nutri.profile-gizi',
                component: () =>
                  import('@/views/meal-plan/ahli-gizi/index.vue'),
              },
              {
                path: 'edit-profile-gizi',
                name: 'nutri.profile-client-edit',
                component: () =>
                  import('@/views/meal-plan/edit-profile-client.vue'),
              },
              {
                path: 'edit-nutrition',
                name: 'nutri.nutrition-edit',
                component: () => import('@/views/meal-plan/edit-nutrition.vue'),
              },
              {
                path: 'edit-body-composition',
                name: 'nutri.body-composition-edit',
                component: () =>
                  import('@/views/meal-plan/edit-body-composition.vue'),
              },
              {
                path: 'edit-nutrition-advice',
                name: 'nutri.nutrition-advice-edit',
                component: () =>
                  import('@/views/meal-plan/edit-nutrition-advice.vue'),
              },
            ],
          },
          {
            path: 'quizionary/:programId',
            name: 'nutri.detail-quizionary',
            component: () =>
              import('@/views/nutritionists/quizionary/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isNutritionist: true,
            },
          },
          {
            path: 'adime-note',
            component: {
              render: (h) => h('router-view'),
            },
            children: [
              {
                path: '',
                name: 'nutri.adime-note',
                component: () =>
                  import('@/views/nutritionists/adime-note/index.vue'),
              },
              {
                path: 'history',
                name: 'nutri.adime-note.history',
                component: () =>
                  import('@/views/nutritionists/adime-note/history/index.vue'),
              },
              {
                path: 'f',
                name: 'nutri.adime-note.form',
                component: () =>
                  import('@/views/nutritionists/adime-note/form/index.vue'),
              },
            ],
          },
          {
            path: 'follow-up-notes',
            component: {
              render: (h) => h('router-view'),
            },
            children: [
              {
                path: '',
                name: 'nutri.follow-up-notes',
                component: () =>
                  import('@/views/nutritionists/follow-up-notes/index.vue'),
              },
              {
                path: 'history',
                name: 'nutri.follow-up-notes.history',
                component: () =>
                  import(
                    '@/views/nutritionists/follow-up-notes/history/index.vue'
                  ),
              },
              {
                path: 'f',
                name: 'nutri.follow-up-notes.form',
                component: () =>
                  import(
                    '@/views/nutritionists/follow-up-notes/form/index.vue'
                  ),
              },
            ],
          },
          {
            path: 'progress-tracker',
            component: () =>
              import('@/views/nutritionists/progress-tracker/index.vue'),
            children: [
              {
                path: '',
                name: 'nutri.progress-tracker',
                redirect: { name: 'nutri.progress-tracker.master' },
              },
              {
                path: 'i',
                name: 'nutri.progress-tracker.indikator',
                component: () =>
                  import(
                    '@/views/nutritionists/progress-tracker/indikator/index.vue'
                  ),
              },
              {
                path: 'm',
                component: {
                  render: (h) => h('router-view'),
                },
                children: [
                  {
                    path: '',
                    name: 'nutri.progress-tracker.master',
                    component: () =>
                      import(
                        '@/views/nutritionists/progress-tracker/master/index.vue'
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: 'progress-tracker/edit',
            name: 'nutri.progress-tracker.edit',
            component: () =>
              import(
                '@/views/nutritionists/progress-tracker/master/edit/index.vue'
              ),
          },
          {
            path: 'programs',
            component: {
              name: 'ProgramsContainer',
              render(h) {
                return h('router-view')
              },
            },
            children: [
              {
                path: ':programId',
                name: 'nutri.program.detail',
                component: () => import(
                  '@/views/management-client/[id]/programs/[programId]/index.vue'
                ),
                meta: {
                  requiresAuth: true,
                  isNutritionist: true,
                },
              },
              {
                path: ':programId/meal-plan',
                component: () => import(
                  '@/views/management-client/[id]/programs/[programId]/meal-plan/layout.vue'
                ),
                children: [
                  {
                    path: ':month/profile',
                    name: 'nutri.meal-plan.profile.detail',
                    component: () => import(
                      '@/views/management-client/[id]/programs/[programId]/meal-plan/profile/index.vue'
                    ),
                    meta: {
                      requiresAuth: true,
                      isNutritionist: true,
                    },
                  },
                  {
                    path: ':month/profile/edit',
                    name: 'nutri.meal-plan.profile.edit',
                    component: () => import(
                      '@/views/management-client/[id]/programs/[programId]/meal-plan/profile/edit.vue'
                    ),
                    meta: {
                      requiresAuth: true,
                      isNutritionist: true,
                    },
                  },
                  {
                    path: ':month/meal-plan-schedule',
                    name: 'nutri.meal-plan.meal-plan-schedule.detail',
                    component: () => import(
                      '@/views/management-client/[id]/programs/[programId]/meal-plan/meal-plan-schedule/index.vue'
                    ),
                    meta: {
                      requiresAuth: true,
                      isNutritionist: true,
                    },
                  },
                  {
                    path: ':month/meal-plan-schedule/edit',
                    name: 'nutri.meal-plan.meal-plan-schedule.edit',
                    component: () => import(
                      '@/views/management-client/[id]/programs/[programId]/meal-plan/meal-plan-schedule/edit.vue'
                    ),
                    meta: {
                      requiresAuth: true,
                      isNutritionist: true,
                    },
                  },
                  {
                    path: ':month/menu-recommendation',
                    name: 'nutri.meal-plan.menu-recommendation.detail',
                    component: () => import(
                      '@/views/management-client/[id]/programs/[programId]/meal-plan/menu-recommendation/index.vue'
                    ),
                    meta: {
                      requiresAuth: true,
                      isNutritionist: true,
                    },
                  },
                  {
                    path: ':month/menu-recommendation/edit',
                    name: 'nutri.meal-plan.menu-recommendation.edit',
                    component: () => import(
                      '@/views/management-client/[id]/programs/[programId]/meal-plan/menu-recommendation/edit.vue'
                    ),
                    meta: {
                      requiresAuth: true,
                      isNutritionist: true,
                      sideMenu: false,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/progress-tracker/feedback/:clientId/:progressId',
    name: 'nutri.progress-tracker.detail',
    component: () => import('@/views/nutritionists/progress-tracker/detail/index.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/meal-plan',
    component: () => import('@/views/meal-plan/layout.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
    children: [
      {
        path: '',
        name: 'meal-plan',
        component: () => import('@/views/meal-plan/index.vue'),
      },
      {
        path: '/edit-meal-plan',
        name: 'meal-plan-edit',
        component: () => import('@/views/meal-plan/edit.vue'),
      },
      {
        path: '/edit-profile-client',
        name: 'profile-client-edit',
        component: () => import('@/views/meal-plan/edit-profile-client.vue'),
      },
      {
        path: '/edit-nutrition',
        name: 'nutrition-edit',
        component: () => import('@/views/meal-plan/edit-nutrition.vue'),
      },
      {
        path: '/edit-body-composition',
        name: 'body-composition-edit',
        component: () => import('@/views/meal-plan/edit-body-composition.vue'),
      },
      {
        path: '/edit-nutrition-advice',
        name: 'nutrition-advice-edit',
        component: () => import('@/views/meal-plan/edit-nutrition-advice.vue'),
      },
      {
        path: '/edit-menu-recomendation',
        name: 'menu-recomendation-edit',
        component: () => import('@/views/meal-plan/menu-recommendation/edit.vue'),
      },
      {
        path: 'recommended-menu',
        name: 'meal-plan.rekomendasi-menu',
        component: () => import('@/views/meal-plan/menu-recommendation/index.vue'),
      },
      {
        path: 'health-profile',
        name: 'meal-plan.profile-gizi',
        component: () => import('@/views/meal-plan/ahli-gizi/index.vue'),
      },
      {
        path: ':clientId',
        name: 'meal-plan.with-id',
        component: () => import('@/views/meal-plan/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
      },
    ],
  },
  {
    path: '/progress-tracker',
    component: () => import('@/views/client/progress-tracker/index.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
    children: [
      {
        path: '',
        name: 'client.progress-tracker',
        redirect: 'current',
      },
      {
        path: 'current',
        name: 'client.progress-tracker.current',
        component: () => import('@/views/client/progress-tracker/current.vue'),
      },
      {
        path: 'history',
        name: 'client.progress-tracker.history',
        component: () => import('@/views/client/progress-tracker/history.vue'),
      },
    ],
  },
  {
    path: '/progress-tracker/detail/:progressId',
    name: 'client.progress-tracker.detail',
    component: () => import('@/views/client/progress-tracker/detail/index.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/progress-tracker/submission/:date',
    name: 'client.progress-tracker.submission',
    component: () => import('@/views/client/progress-tracker/submission.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/progress-tracker/success',
    name: 'client.progress-tracker.success',
    component: () => import('@/views/client/progress-tracker/success.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/notes',
    component: () => import('@/views/client/follow-up-notes/layout.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
    children: [
      {
        path: '',
        name: 'client.follow-up-notes',
        component: () => import('@/views/client/follow-up-notes/index.vue'),
      },
    ],
  },

  // ADMIN
  {
    path: ROUTE.ADMIN.INDEX.PATH,
    component: () => import('@/views/admin/layout.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: ROUTE.ADMIN.INDEX.PATH,
        name: ROUTE.ADMIN.INDEX.NAME,
        component: () => import('@/views/admin/dashboard/index.vue'),
        meta: {
          isAdmin: true,
        },
      },
      {
        path: ROUTE.ADMIN.NOTIF.PATH,
        name: ROUTE.ADMIN.NOTIF.NAME,
        component: () => import('@/views/admin/notification.vue'),
        meta: {
          isAdmin: true,
        },
      },
      {
        path: ROUTE.ADMIN.DIET.PATH,
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: ROUTE.ADMIN.DIET.PATH,
            name: ROUTE.ADMIN.DIET.NAME,
            component: () => import('@/views/admin/diet-program/index.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.DIET_ADD.PATH,
            name: ROUTE.ADMIN.DIET_ADD.NAME,
            component: () => import('@/views/admin/diet-program/form.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.DIET_EDIT.PATH,
            name: ROUTE.ADMIN.DIET_EDIT.NAME,
            component: () => import('@/views/admin/diet-program/form.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.DIET_DETAIL.PATH,
            name: ROUTE.ADMIN.DIET_DETAIL.NAME,
            component: () => import('@/views/admin/diet-program/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isAdmin: true,
            },
          },
        ],
      },
      {
        path: ROUTE.ADMIN.CLIENT.PATH,
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: ROUTE.ADMIN.CLIENT.PATH,
            name: ROUTE.ADMIN.CLIENT.NAME,
            component: () => import('@/views/admin/clients/index.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.CLIENT_DETAIL.PATH,
            component: {
              render: (h) => h('router-view'),
            },
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
            children: [
              {
                path: ROUTE.ADMIN.CLIENT_DETAIL.PATH,
                name: ROUTE.ADMIN.CLIENT_DETAIL.NAME,
                component: () => import('@/views/admin/clients/detail.vue'),
                meta: {
                  isAdmin: true,
                },
              },
              {
                path: 'p',
                component: {
                  render: (h) => h('router-view'),
                },
                children: [
                  {
                    path: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL.PATH,
                    component: {
                      render: (h) => h('router-view'),
                    },
                    meta: {
                      sitemap: {
                        ignoreRoute: true,
                      },
                    },
                    children: [
                      {
                        path: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL.PATH,
                        name: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL.NAME,
                        component: () =>
                          import('@/views/admin/clients/programs/index.vue'),
                        meta: {
                          isAdmin: true,
                        },
                      },
                      {
                        path: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_CHANGE_NUTRI.PATH,
                        name: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_CHANGE_NUTRI.NAME,
                        component: () =>
                          import('@/views/admin/clients/nutris/index.vue'),
                        meta: {
                          isAdmin: true,
                        },
                      },
                      {
                        path: 'meal-plan',
                        component: () => import(
                          '@/views/admin/clients/meal-plan/layout.vue'
                        ),
                        children: [
                          {
                            path: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_MEALPLAN_PROFILE.PATH,
                            name: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_MEALPLAN_PROFILE.NAME,
                            component: () => import(
                              '@/views/admin/clients/meal-plan/profile/index.vue'
                            ),
                            meta: {
                              requiresAuth: true,
                              isAdmin: true,
                            },
                          },
                          {
                            path: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_MEALPLAN_SCHEDULE.PATH,
                            name: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_MEALPLAN_SCHEDULE.NAME,
                            component: () => import(
                              '@/views/admin/clients/meal-plan/meal-plan-schedule/index.vue'
                            ),
                            meta: {
                              requiresAuth: true,
                              isAdmin: true,
                            },
                          },
                          {
                            path: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_MEALPLAN_RECOMMENDATION.PATH,
                            name: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_MEALPLAN_RECOMMENDATION.NAME,
                            component: () => import(
                              '@/views/admin/clients/meal-plan/menu-recommendation/index.vue'
                            ),
                            meta: {
                              requiresAuth: true,
                              isAdmin: true,
                            },
                          },
                        ],
                      },
                      // {
                      //   path: 'mp',
                      //   component: {
                      //     name: 'AdminClientMealPlanContainer',
                      //     render: (h) => h('router-view'),
                      //   },
                      //   children: [
                      //     {
                      //       path: '',
                      //       name: 'admin.clients-mealplan',
                      //       redirect: 'plan',
                      //       meta: {
                      //         isAdmin: true,
                      //       },
                      //     },
                      //     {
                      //       path: ':moduleId',
                      //       name: 'admin.clients-mealplan.module',
                      //       component: () =>
                      //         import(
                      //           '@/views/admin/clients/mealplan/index.vue'
                      //         ),
                      //       meta: {
                      //         sitemap: {
                      //           ignoreRoute: true,
                      //         },
                      //         isAdmin: true,
                      //       },
                      //     },
                      //   ],
                      // },
                      {
                        path: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_QUIZIONARY.PATH,
                        name: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_QUIZIONARY.NAME,
                        component: () =>
                          import('@/views/admin/clients/quizionary/index.vue'),
                        meta: {
                          isAdmin: true,
                        },
                      },
                      {
                        path: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_NOTE.PATH,
                        name: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_NOTE.NAME,
                        component: () =>
                          import(
                            '@/views/admin/clients/follow-up-notes/index.vue'
                          ),
                        meta: {
                          isAdmin: true,
                        },
                      },
                      {
                        path: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_PROGRESS.PATH,
                        name: ROUTE.ADMIN.CLIENT_DETAIL_PROGRAM_DETAIL_PROGRESS.NAME,
                        component: () =>
                          import(
                            '@/views/admin/clients/progress/index.vue'
                          ),
                        meta: {
                          isAdmin: true,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: ROUTE.ADMIN.COACHING.PATH,
        component: {
          render: (h) => h('router-view'),
        },
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
        children: [
          {
            path: ROUTE.ADMIN.COACHING.PATH,
            name: ROUTE.ADMIN.COACHING.NAME,
            component: () =>
              import('@/views/admin/coaching-challenge/index.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.COACHING_ADD.PATH,
            name: ROUTE.ADMIN.COACHING_ADD.NAME,
            component: () =>
              import('@/views/admin/coaching-challenge/form.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.COACHING_EDIT.PATH,
            name: ROUTE.ADMIN.COACHING_EDIT.NAME,
            component: () =>
              import('@/views/admin/coaching-challenge/form.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.COACHING_CATEGORY.PATH,
            name: ROUTE.ADMIN.COACHING_CATEGORY.NAME,
            component: () =>
              import('@/views/admin/coaching-challenge/category.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.COACHING_DETAIL.PATH,
            name: ROUTE.ADMIN.COACHING_DETAIL.NAME,
            component: () =>
              import('@/views/admin/coaching-challenge/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isAdmin: true,
            },
          },
        ],
      },
      {
        path: ROUTE.ADMIN.MANAGE_NUTRITIONIST.PATH,
        component: {
          render: (h) => h('router-view'),
        },
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
        children: [
          {
            path: ROUTE.ADMIN.MANAGE_NUTRITIONIST.PATH,
            name: ROUTE.ADMIN.MANAGE_NUTRITIONIST.NAME,
            component: () => import('@/views/admin/nutritionists/index.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.MANAGE_NUTRITIONIST_ADD.PATH,
            name: ROUTE.ADMIN.MANAGE_NUTRITIONIST_ADD.NAME,
            component: () => import('@/views/admin/nutritionists/form.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.MANAGE_NUTRITIONIST_EDIT.PATH,
            name: ROUTE.ADMIN.MANAGE_NUTRITIONIST_EDIT.NAME,
            component: () => import('@/views/admin/nutritionists/form.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isAdmin: true,
            },
          },
          {
            path: ROUTE.ADMIN.MANAGE_NUTRITIONIST_DETAIL.PATH,
            name: ROUTE.ADMIN.MANAGE_NUTRITIONIST_DETAIL.NAME,
            component: () => import('@/views/admin/nutritionists/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isAdmin: true,
            },
          },
        ],
      },
      {
        path: '/admin/referrals',
        name: 'admin.referrals',
        component: () => import('@/views/admin/referrals/index.vue'),
        meta: {
          name: 'description',
          content: 'My page meta description',
          sitemap: {
            ignoreRoute: true,
          },
          isAdmin: true,
        },
      },
      {
        path: '/admin/referrals/gift',
        name: 'admin.referrals.gift',
        component: () => import('@/views/admin/referrals/gift/index.vue'),
        meta: {
          name: 'description',
          content: 'My page meta description',
          sitemap: {
            ignoreRoute: true,
          },
          isAdmin: true,
        },
      },
      {
        path: '/admin/referrals/:id/detail',
        name: 'admin.referrals.detail',
        component: () => import('@/views/admin/referrals/detail.vue'),
        meta: {
          name: 'description',
          content: 'My page meta description',
          sitemap: {
            ignoreRoute: true,
          },
          isAdmin: true,
        },
      },
      {
        path: '/admin/referrals/:id/edit',
        name: 'admin.referrals.edit',
        component: () => import('@/views/admin/referrals/gift/index.vue'),
        meta: {
          name: 'description',
          content: 'My page meta description',
          sitemap: {
            ignoreRoute: true,
          },
          isAdmin: true,
        },
      },
      {
        path: '/admin/referrals/schema',
        name: 'admin.referrals.schema',
        component: () => import('@/views/admin/referrals/schema/index.vue'),
        meta: {
          name: 'description',
          content: 'My page meta description',
          sitemap: {
            ignoreRoute: true,
          },
          isAdmin: true,
        },
      },
      {
        path: '/admin/referrals/schema/:id',
        name: 'admin.referrals.schema.detail',
        component: () => import('@/views/admin/referrals/schema/detail.vue'),
        meta: {
          name: 'description',
          content: 'My page meta description',
          sitemap: {
            ignoreRoute: true,
          },
          isAdmin: true,
        },
      },
      {
        path: '/admin/referrals/schema/:id/edit',
        name: 'admin.referrals.schema.edit',
        component: () => import('@/views/admin/referrals/schema/index.vue'),
        meta: {
          name: 'description',
          content: 'My page meta description',
          sitemap: {
            ignoreRoute: true,
          },
          isAdmin: true,
        },
      },
      {
        path: '/admin/notifications',
        name: 'admin.notifications',
        component: () => import('@/views/admin/notifications/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          isAdmin: true,
        },
      },
      {
        path: '/admin/diet-kuesioner',
        component: {
          render: (h) => h('router-view'),
        },
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
        children: [
          {
            path: '',
            name: 'admin.diet-kuesioner',
            component: () => import('@/views/admin/diet-kuesioner/index.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: 'add',
            name: 'admin.diet-kuesioner.add',
            component: () => import('@/views/admin/diet-kuesioner/form.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: 'edit/:id',
            name: 'admin.diet-kuesioner.edit',
            component: () => import('@/views/admin/diet-kuesioner/form.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isAdmin: true,
            },
          },
          {
            path: ':id',
            name: 'admin.diet-kuesioner.detail',
            component: () => import('@/views/admin/diet-kuesioner/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isAdmin: true,
            },
          },
        ],
      },
      {
        path: 'glossaries',
        component: {
          render: (h) => h('router-view'),
        },
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
        children: [
          {
            path: '',
            name: 'admin.glossaries',
            component: () => import('@/views/admin/glossary/index.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: 'new',
            name: 'admin.glossaries-new',
            component: () => import('@/views/admin/glossary/edit-and-create.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: ':glossaryId',
            component: {
              render: (h) => h('router-view'),
            },
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
            children: [
              {
                path: '',
                name: 'admin.glossaries-detail',
                component: () => import('@/views/admin/glossary/detail.vue'),
                meta: {
                  isAdmin: true,
                },
              },
              {
                path: 'edit',
                name: 'admin.glossaries-edit',
                component: () => import('@/views/admin/glossary/edit-and-create.vue'),
                meta: {
                  isAdmin: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: '/admin/log-activity',
        name: 'admin.log-activity',
        component: () => import('@/views/admin/log-activity/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          isAdmin: true,
        },
      },
      {
        path: '/admin/coupons',
        component: {
          render: (h) => h('router-view'),
        },
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
        children: [
          {
            path: '',
            name: 'admin.coupons',
            component: () => import('@/views/admin/coupons/index.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: 'add',
            name: 'admin.coupons.add',
            component: () => import('@/views/admin/coupons/add.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: 'edit/:id',
            name: 'admin.coupons.edit',
            component: () => import('@/views/admin/coupons/edit/[id].vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isAdmin: true,
            },
          },
          {
            path: ':id',
            name: 'admin.coupons.detail',
            component: () => import('@/views/admin/coupons/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
              isAdmin: true,
            },
          },
        ],
      },
      {
        path: '/admin/notifications',
        component: {
          render: (h) => h('router-view'),
        },
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
        children: [
          {
            path: '',
            name: 'admin.notifikasi',
            component: () => import('@/views/admin/notifications/index.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: 'new',
            name: 'admin.notifikasi-new',
            component: () => import('@/views/admin/notifications/create.vue'),
            meta: {
              isAdmin: true,
            },
          },
          {
            path: ':notifikasiId',
            component: {
              render: (h) => h('router-view'),
            },
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
            children: [
              {
                path: '',
                name: 'admin.notifikasi-detail',
                component: () =>
                  import('@/views/admin/notifications/detail.vue'),
                meta: {
                  isAdmin: true,
                },
              },
              {
                path: 'edit',
                name: 'admin.notifikasi-edit',
                component: () => import('@/views/admin/notifications/edit.vue'),
                meta: {
                  isAdmin: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },

  // SUPERADMIN
  {
    path: '/management',
    component: () => import('@/views/superadmin/layout.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'superadmin',
        component: () => import('@/views/superadmin/dashboard/index.vue'),
      },
      {
        path: '/management/diet-program',
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: '',
            name: 'superadmin.diet-program',
            component: () =>
              import('@/views/superadmin/diet-program/index.vue'),
          },
          {
            path: 'add',
            name: 'superadmin.diet-program.add',
            component: () => import('@/views/superadmin/diet-program/form.vue'),
          },
          {
            path: 'edit/:id',
            name: 'superadmin.diet-program.edit',
            component: () => import('@/views/superadmin/diet-program/form.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
          {
            path: ':id',
            name: 'superadmin.diet-program.detail',
            component: () =>
              import('@/views/superadmin/diet-program/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
        ],
      },
      {
        path: '/management/admin',
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: '',
            name: 'superadmin.admin',
            component: () => import('@/views/superadmin/admin/index.vue'),
          },
          {
            path: 'add',
            name: 'superadmin.admin.add',
            component: () => import('@/views/superadmin/admin/form.vue'),
          },
          {
            path: 'edit/:id',
            name: 'superadmin.admin.edit',
            component: () => import('@/views/superadmin/admin/form.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
        ],
      },
      {
        path: '/management/clients',
        component: {
          render: (h) => h('router-view'),
        },
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
        children: [
          {
            path: '',
            name: 'superadmin.clients',
            component: () => import('@/views/superadmin/clients/index.vue'),
          },
          {
            path: 'new',
            name: 'superadmin.clients-new',
            component: () => import('@/views/superadmin/clients/create.vue'),
          },
          {
            path: ':clientId',
            component: {
              render: (h) => h('router-view'),
            },
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
            children: [
              {
                path: '',
                name: 'superadmin.clients-detail',
                component: () =>
                  import('@/views/superadmin/clients/detail.vue'),
              },
              {
                path: 'edit',
                name: 'superadmin.clients-edit',
                component: () => import('@/views/superadmin/clients/edit.vue'),
              },
              {
                path: 'p',
                component: {
                  render: (h) => h('router-view'),
                },
                children: [
                  {
                    path: ':programId',
                    component: {
                      render: (h) => h('router-view'),
                    },
                    meta: {
                      sitemap: {
                        ignoreRoute: true,
                      },
                    },
                    children: [
                      {
                        path: '',
                        name: 'superadmin.clients-programs',
                        component: () =>
                          import(
                            '@/views/superadmin/clients/programs/index.vue'
                          ),
                      },
                      {
                        path: 'nutris',
                        name: 'superadmin.clients-nutris',
                        component: () =>
                          import('@/views/superadmin/clients/nutris/index.vue'),
                      },
                      {
                        path: 'mp',
                        component: {
                          render: (h) => h('router-view'),
                        },
                        children: [
                          {
                            path: '',
                            name: 'superadmin.clients-mealplan',
                            redirect: 'plan',
                          },
                          {
                            path: ':moduleId',
                            name: 'superadmin.clients-mealplan.module',
                            component: () =>
                              import(
                                '@/views/superadmin/clients/mealplan/index.vue'
                              ),
                            meta: {
                              sitemap: {
                                ignoreRoute: true,
                              },
                            },
                          },
                        ],
                      },
                      {
                        path: 'quizionary',
                        name: 'superadmin.clients-quizionary',
                        component: () =>
                          import(
                            '@/views/superadmin/clients/quizionary/index.vue'
                          ),
                      },
                      {
                        path: 'note',
                        name: 'superadmin.clients-followup-notes',
                        component: () =>
                          import(
                            '@/views/superadmin/clients/follow-up-notes/index.vue'
                          ),
                      },
                      {
                        path: 'progress',
                        name: 'superadmin.clients-progress',
                        component: () =>
                          import(
                            '@/views/superadmin/clients/progress/index.vue'
                          ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/management/coaching-challenge',
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: '',
            name: 'superadmin.coaching-challenge',
            component: () =>
              import('@/views/superadmin/coaching-challenge/index.vue'),
          },
          {
            path: 'add',
            name: 'superadmin.coaching-challenge.add',
            component: () =>
              import('@/views/superadmin/coaching-challenge/form.vue'),
          },
          {
            path: 'edit/:id',
            name: 'superadmin.coaching-challenge.edit',
            component: () =>
              import('@/views/superadmin/coaching-challenge/form.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
          {
            path: ':id',
            name: 'superadmin.coaching-challenge.detail',
            component: () =>
              import('@/views/superadmin/coaching-challenge/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
        ],
      },
      {
        path: '/management/nutritionists',
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: '',
            name: 'superadmin.nutritionists',
            component: () =>
              import('@/views/superadmin/nutritionists/index.vue'),
          },
          {
            path: 'add',
            name: 'superadmin.nutritionists.add',
            component: () =>
              import('@/views/superadmin/nutritionists/form.vue'),
          },
          {
            path: 'edit/:id',
            name: 'superadmin.nutritionists.edit',
            component: () =>
              import('@/views/superadmin/nutritionists/form.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
          {
            path: ':id',
            name: 'superadmin.nutritionists.detail',
            component: () =>
              import('@/views/superadmin/nutritionists/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
        ],
      },
      {
        path: '/management/notifications',
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: '',
            name: 'superadmin.notifikasi',
            component: () =>
              import('@/views/superadmin/notifications/index.vue'),
          },
          {
            path: 'new',
            name: 'superadmin.notifikasi-new',
            component: () =>
              import('@/views/superadmin/notifications/create.vue'),
          },
          {
            path: ':notifikasiId',
            component: {
              render: (h) => h('router-view'),
            },
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
            children: [
              {
                path: '',
                name: 'superadmin.notifikasi-detail',
                component: () =>
                  import('@/views/superadmin/notifications/detail.vue'),
              },
              {
                path: 'edit',
                name: 'superadmin.notifikasi-edit',
                component: () =>
                  import('@/views/superadmin/notifications/edit.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/management/diet-kuesioner',
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: '',
            name: 'superadmin.diet-kuesioner',
            component: () =>
              import('@/views/superadmin/diet-kuesioner/index.vue'),
          },
          {
            path: 'add',
            name: 'superadmin.diet-kuesioner.add',
            component: () =>
              import('@/views/superadmin/diet-kuesioner/form.vue'),
          },
          {
            path: 'edit/:id',
            name: 'superadmin.diet-kuesioner.edit',
            component: () =>
              import('@/views/superadmin/diet-kuesioner/form.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
          {
            path: ':id',
            name: 'superadmin.diet-kuesioner.detail',
            component: () =>
              import('@/views/superadmin/diet-kuesioner/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
        ],
      },
      {
        path: '/management/glossary',
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: '',
            name: 'superadmin.glossary',
            component: () => import('@/views/superadmin/glossary/index.vue'),
          },
          {
            path: 'new',
            name: 'superadmin.glossary-new',
            component: () => import('@/views/superadmin/glossary/create.vue'),
          },
          {
            path: ':glossaryId',
            component: {
              render: (h) => h('router-view'),
            },
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
            children: [
              {
                path: '',
                name: 'superadmin.glossary-detail',
                component: () =>
                  import('@/views/superadmin/glossary/detail.vue'),
              },
              {
                path: 'edit',
                name: 'superadmin.glossary-edit',
                component: () => import('@/views/superadmin/glossary/edit.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/management/log-activity',
        name: 'superadmin.log-activity',
        component: () => import('@/views/superadmin/log-activity/index.vue'),
      },
      {
        path: '/management/coupons',
        component: {
          render: (h) => h('router-view'),
        },
        children: [
          {
            path: '',
            name: 'superadmin.coupons',
            component: () => import('@/views/superadmin/coupons/index.vue'),
          },
          {
            path: 'add',
            name: 'superadmin.coupons.add',
            component: () => import('@/views/superadmin/coupons/form.vue'),
          },
          {
            path: 'edit/:id',
            name: 'superadmin.coupons.edit',
            component: () => import('@/views/superadmin/coupons/form.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
          {
            path: ':id',
            name: 'superadmin.coupons.detail',
            component: () => import('@/views/superadmin/coupons/detail.vue'),
            meta: {
              sitemap: {
                ignoreRoute: true,
              },
            },
          },
        ],
      },
    ],
  },

  // START - DEVELOPMENT ONLY
  // {
  //   path: '/design-system',
  //   component: () => import('@/views/client/layout.vue'),
  //   children: [
  //     {
  //       path: 'base-menu',
  //       component: () => import('@/components/elements/example/example-base-menu.vue'),
  //       meta: {
  //         sitemap: {
  //           ignoreRoute: true,
  //         },
  //         title: 'xxx',
  //         bottomNavbar: false,
  //         footer: true,
  //         withBack: true,
  //         notification: true,
  //         cart: true,
  //         sideMenu: false,
  //         floatingChat: true,
  //       },
  //     },
  //     {
  //       path: 'base-input-date',
  //       component: () => import('@/components/elements/example/example-base-input-date.vue'),
  //       meta: {
  //         sitemap: {
  //           ignoreRoute: true,
  //         },
  //         title: 'xxx',
  //         bottomNavbar: false,
  //         footer: true,
  //         withBack: true,
  //         notification: true,
  //         cart: true,
  //         sideMenu: false,
  //         floatingChat: true,
  //       },
  //     },
  //     {
  //       path: 'base-input-select',
  //       component: () => import('@/components/elements/example/example-base-input-select.vue'),
  //       meta: {
  //         sitemap: {
  //           ignoreRoute: true,
  //         },
  //         title: 'xxx',
  //         bottomNavbar: false,
  //         footer: true,
  //         withBack: true,
  //         notification: true,
  //         cart: true,
  //         sideMenu: false,
  //         floatingChat: true,
  //       },
  //     },
  //   ],
  // },
  // END - DEVELOPMENT ONLY

  // BAK
  // {
  //   path: '/term-condition',
  //   name: 'client.termcondition',
  //   component: () => import('@/views/client/termCondition.vue'),
  //   meta: {
  //     sitemap: {
  //       ignoreRoute: true,
  //     },
  //     requiresAuth: false,
  //   },
  // },
  // {
  //   path: '/privacy-policy',
  //   name: 'client.privacypolicy',
  //   component: () => import('@/views/client/privacyPolicy.vue'),
  //   meta: {
  //     sitemap: {
  //       ignoreRoute: true,
  //     },
  //     requiresAuth: false,
  //   },
  // },
  // {
  //   path: '/nutritionists/:nutritionistId',
  //   name: 'client.nutritionists.detail',
  //   component: () => import('@/views/client/nutritionists/detail.vue'),
  //   meta: {
  //     sitemap: {
  //       ignoreRoute: true,
  //     },
  //   },
  // },
  // {
  //   path: '/nutritionist/glossaries',
  //   component: {
  //     component: () => import('@/views/nutritionists/layout.vue'),
  //   },
  //   children: [
  //
  //   ],
  // },
  // {
  //   path: '/cart/checkout',
  //   name: 'client.checkout',
  //   component: () => import('@/views/client/checkout'),
  //   meta: {
  //     sitemap: {
  //       ignoreRoute: true,
  //     },
  //   },
  // },
  // {
  //   path: '/cart/checkout/payment',
  //   name: 'client.payment',
  //   component: () => import('@/views/client/checkout/payment'),
  //   meta: {
  //     sitemap: {
  //       ignoreRoute: true,
  //     },
  //   },
  // },
  // {
  //   path: '/paid/success',
  //   name: 'client.success',
  //   component: () => import('@/views/client/checkout/success.vue'),
  //   meta: {
  //     sitemap: {
  //       ignoreRoute: true,
  //     },
  //   },
  // },

  // BACKEND
  {
    path: ROUTE.BACKEND.INFO_CONSULTATION_END.PATH,
    component: () => import('@/views/client/layout.vue'),
    children: [
      {
        path: ROUTE.BACKEND.INFO_CONSULTATION_END.PATH,
        name: ROUTE.BACKEND.INFO_CONSULTATION_END.NAME,
        component: () => import('@/views/info-konsultasi-berakhir/index.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          ...meta({
            footer: true,
            withBack: true,
            notification: true,
            cart: true,
          }),
        },
      },
    ],
  },

  // GENERAL
  {
    path: '*',
    component: () => import('@/views/client/layout.vue'),
    children: [
      {
        path: ROUTE.GENERAL.TERM_CONDITION.PATH,
        name: ROUTE.GENERAL.TERM_CONDITION.NAME,
        component: () => import('@/views/general/term-condition.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          ...meta({
            title: 'Syarat Ketentuan',
            footer: true,
            withBack: true,
            notification: true,
            cart: true,
            floatingChat: true,
          }),
        },
      },
      {
        path: ROUTE.GENERAL.PRIVACY_POLICY.PATH,
        name: ROUTE.GENERAL.PRIVACY_POLICY.NAME,
        component: () => import('@/views/general/privacy-policy.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          ...meta({
            title: 'Privacy Policy',
            footer: true,
            withBack: true,
            notification: true,
            cart: true,
            floatingChat: true,
          }),
        },
      },
      {
        path: ROUTE.GENERAL.SPECIAL_TERM.PATH,
        name: ROUTE.GENERAL.SPECIAL_TERM.NAME,
        component: () => import('@/views/general/special-term.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          ...meta({
            title: 'Ketentuan Khusus Layanan',
            footer: true,
            withBack: true,
            notification: true,
            cart: true,
            floatingChat: true,
          }),
        },
      },
      {
        path: ROUTE.GENERAL.BLOG.PATH,
        name: ROUTE.GENERAL.BLOG.NAME,
        component: () => import('@/views/general/blog.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          ...meta({
            title: 'Blog',
            footer: true,
            withBack: true,
            notification: true,
            cart: true,
            floatingChat: true,
          }),
        },
      },
      {
        path: ROUTE.GENERAL.ABOUT.PATH,
        name: ROUTE.GENERAL.ABOUT.NAME,
        component: () => import('@/views/general/about-us.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          ...meta({
            title: 'Tentang Dietela',
            footer: true,
            withBack: true,
            notification: true,
            cart: true,
            floatingChat: true,
          }),
        },
      },
      {
        path: ROUTE.GENERAL.MAINTENANCE.PATH,
        name: ROUTE.GENERAL.MAINTENANCE.NAME,
        component: () => import('@/views/general/under-maintenance.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          ...meta({
            title: 'Under Maintenance',
            footer: true,
            withBack: true,
            notification: true,
            cart: true,
            floatingChat: true,
          }),
        },
      },
      {
        path: ROUTE.GENERAL.NOT_FOUND.PATH,
        name: ROUTE.GENERAL.NOT_FOUND.NAME,
        component: () => import('@/views/general/404.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
          title: 'Page Not Found',
          bottomNavbar: false,
          footer: true,
          withBack: true,
          notification: true,
          cart: true,
          sideMenu: false,
          floatingChat: true,
          requiresAuth: false,
        },
      },
    ],
  },
]

module.exports = { routes }
