export default function getMenuCategory(menu) {
  switch (menu) {
    case 'morningSnack':
      return 'Snack pagi'
    case 'afternoonSnack':
      return 'Snack siang'
    case 'lunch':
      return 'Makan siang'
    case 'dinner':
      return 'Makan malam'
    case 'nightSnack':
      return 'Snack malam'
    case 'breakfast':
    default:
      return 'Sarapan'
  }
}

export const listMenuCategory = [
  {
    id: 'breakfast',
    label: 'Sarapan',
  },
  {
    id: 'morningSnack',
    label: 'Snack pagi',
  },
  {
    id: 'lunch',
    label: 'Makan siang',
  },
  {
    id: 'afternoonSnack',
    label: 'Snack siang',
  },
  {
    id: 'dinner',
    label: 'Makan malam',
  },
  {
    id: 'nightSnack',
    label: 'Snack malam',
  },
]
